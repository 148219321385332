<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4519_5382)">
      <path
        d="M6.125 13.4971H3C2.44772 13.4971 2 13.0494 2 12.4971V3.49707C2 2.94479 2.44772 2.49707 3 2.49707H12C12.5523 2.49707 13 2.94479 13 3.49707V5.93457"
        stroke="#868D9C"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M12 9.5V15.4994"
        stroke="#868D9C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.00061 10.8332V9.5H15V10.8332"
        stroke="#868D9C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6677 15.4995H13.3341"
        stroke="#868D9C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4519_5382">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
